<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        ref="addNewSwapForm"
        v-model="isFormValid"
        @submit.prevent="submitNewSwapForm"
      >
        <v-row class="mt-4">
          <v-col class="pt-0">
            <ChooseOrderPartial
              v-if="!specificContainer || orderFetched"
              :order-data="order"
              :payment-data="previousPayment"
              :specific-client="specificClient"
              swap
              :schedule="schedule"
              :distance="straightLineDistance"
              :prepopulate="specificContainer"
              :disable-suggested-order-price="isFormDisabled"
              @update="updateFormValue"
            />
            <OrderWarnings :order="order" />
          </v-col>
          <v-col class="pt-0">
            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <NewCoursePartial
                  :course-data="course"
                  :order-data="order"
                  :schedule="!specificContainer && schedule"
                  :disabled="isFormDisabled"
                  swap
                  @update="updateFormValue"
                />
              </v-col>
              <v-col class="pt-0">
                <NewPaymentPartial
                  ref="payment"
                  :payment-data="payment"
                  :client-type="clientType"
                  course-type="Wymiana"
                  :location-discount="locationDiscount"
                  :distance="straightLineDistance"
                  :disabled="isFormDisabled"
                  @update="updateFormValue"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        class="base-hover"
        :loading="isProcessing"
        :disabled="isSubmitDisabled"
        @click="submitNewSwapForm()"
      >
        Stwórz {{ subjectInAccusative }}
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { parseAsBasicUnit, getDistanceBetweenTwoPoints, getGrossValue } from '../../utils'
import rules from '../../utils/validators'
import NewCoursePartial from './Partials/NewCourse'
import NewPaymentPartial from './Partials/NewPayment'
import ChooseOrderPartial from './Partials/ChooseOrder'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import updateFormValue from '../../mixins/updateFormValue.vue'
import OrderWarnings from './OrderWarnings.vue'
import api from '../../api/v1'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import { SimplifiedOrder, Swap, Course } from '../../models'
import get from 'lodash/get'

export default {
  components: {
    NewCoursePartial,
    NewPaymentPartial,
    DialogFormWrapper,
    ChooseOrderPartial,
    OrderWarnings,
  },
  mixins: [updateFormValue, afterFormSubmitted],
  props: {
    specificClient: {
      type: Boolean,
      default: false
    },
    specificContainer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules,
      isFormValid: true,
      locationDiscount: null,
      orderFetched: false,
      previousPayment: {},
      destinationCoordinates: null,
      clientType: null,
      isPriceCalculationAllowed: true,
      ...new Swap()
    }
  },
  computed: {
    ...mapState({
      department: state => state.core.department,
      schedule: state => state.layout.dialog.data?.schedule,
      dialogObject: state => state.layout.dialog,
      swappingOrder: state => state.order.entity,
      ordersList: state => state.orders.simplifiedList,
      productsList: state => state.orders.newOrderProducts,
      isProcessing: state => state.orders.isProcessing
    }),
    dialog () {
      const { item, data: { isCourse } } = this.dialogObject
      const parsedItem = isCourse ? new Course(item) : item
      return { ...this.dialogObject, item: parsedItem }
    },
    priceParams () {
      const { debrisTypeId, containerTypeId } = this.order
      return {
        departmentId: this.department.id,
        debrisTypeId,
        containerTypeId,
        pricingListType: 'debris'
      }
    },
    straightLineDistance () {
      const baseCoordinates = { baseLat: this.department.lat, baseLng: this.department.lng }
      return this.destinationCoordinates ? getDistanceBetweenTwoPoints(baseCoordinates, this.destinationCoordinates) : 0
    },
    isSchedule () {
      return this.order?.scheduleDays?.length
    },
    subjectInAccusative () {
      return this.isSchedule ? 'harmonogram' : 'wymianę'
    },
    isFormDisabled () {
      return !this.order.orderId && !this.order.anyContainer
    },
    isSubmitDisabled () {
      return this.isFormDisabled || !this.payment.debrisNetValue
    }
  },
  watch: {
    priceParams: {
      deep: true,
      handler () {
        const { debrisTypeId, containerTypeId } = this.order || {}
        if (this.isPriceCalculationAllowed && debrisTypeId && containerTypeId) {
          const matchingProduct = this.productsList.find(product => {
            return product.debrisTypeId === debrisTypeId && product.containerTypeId === containerTypeId
          })

          if (matchingProduct) {
            this.payment.debrisNetValue = matchingProduct.netValue / 100
            this.showSnackbar({
              message: ['Zaktualizowano cenę na podstawie produktu.']
            })
          } else {
            this.calculatePrice()
          }
        }
      }
    },
    'order.orderId' (id) {
      if (id && !this.specificContainer) {
        this.isPriceCalculationAllowed = false
        const order = this.ordersList.find(order => order.id === id)
        const {
          address, containerType, debrisType, driverNotes, payment, phoneNumber, userNotes, container, reportedDebrisTypeId, course
        } = new SimplifiedOrder(order)
        this.order.debrisTypeId = debrisType?.id
        this.order.phoneNumber = phoneNumber
        this.order.userNotes = userNotes
        this.order.driverNotes = driverNotes
        this.order.containerTypeId = containerType?.id
        this.payment.debrisNetValue = payment?.debrisNetValue / 100
        this.payment.debrisGrossValue = getGrossValue(payment?.debrisNetValue / 100)
        this.payment.paymentType = payment?.paymentType
        this.payment.paymentDueDays = payment?.paymentDueDays
        this.payment.settlementType = payment?.settlementType
        this.payment.discount = payment?.discount / 100 // editable
        this.payment.discountJustification = payment?.discountJustification
        this.payment.vatPercentage = payment?.vatPercentage
        this.payment.origin = payment?.origin
        this.locationDiscount = address?.discount / 100 // not editable
        this.previousPayment = { ...payment }
        this.order.assignedContainerTypeId = container?.containerType?.id
        this.order.reportedDebrisTypeId = reportedDebrisTypeId
        this.course.timeNotes = course?.timeNotes

        this.$nextTick(() => { this.isPriceCalculationAllowed = true })
      }
    },
    'order.pickupContainerTypeId' (id) {
      if (this.order.anyContainer) this.order.containerTypeId = id
    }
  },
  mounted () {
    if (this.specificContainer) {
      const id = get(this.dialog, this.dialog.data.idPath)
      this.getSingleOrder({ id })
        .then(() => {
          const { order, course: { userNotes, ...courseRest }, payment } = new Swap(this.swappingOrder)
          this.order = order
          this.course = courseRest
          this.payment = payment
          this.clientType = this.swappingOrder.client.clientType
          this.previousPayment = {
            ...payment,
            debrisNetValue: payment.debrisNetValue * 100,
            debrisGrossValue: payment.debrisGrossValue * 100,
          }
          this.$nextTick(() => {
            this.orderFetched = true
          })
        })
    }
  },
  methods: {
    ...mapActions({
      addNewSwapOrder (dispatch, payload) {
        const tableName = this.specificClient ? 'clientOrders' : 'orders'
        return dispatch(`${tableName}/addNewItem`, payload)
      },
      getSingleOrder: 'order/getSingleOrder',
      getSchedules: 'orderSchedules/getItems',
      closeDialog: 'layout/closeDialog',
      showSnackbar: 'snackbar/showSnackbar',
    }),
    calculatePrice () {
      api.calculatePrice(this.priceParams)
        .then((resp) => {
          const debrisNetValue = resp.data.debrisNetValue / 100
          this.payment.origin = 'C' // represents 'Cennik' as price origin
          this.payment.debrisNetValue = debrisNetValue
          this.payment.debrisGrossValue = getGrossValue(debrisNetValue)
        })
    },
    submitNewSwapForm () {
      const isFormValid = this.$refs.addNewSwapForm.validate()

      if (isFormValid) {
        const {
          debrisTypeId,
          containerTypeId,
          pickupContainerTypeId,
          bdoNumber,
          anyContainer,
          addressId,
          ...orderData
        } = this.order
        const { userNotes, leaveNotes, ...courseData } = this.course

        const payment = { ...this.payment }
        payment.debrisNetValue = parseAsBasicUnit(payment.debrisNetValue)
        payment.debrisGrossValue = parseAsBasicUnit(payment.debrisGrossValue)
        payment.discount = parseAsBasicUnit(payment.discount)

        const params = {
          ...orderData,
          ...courseData,
          payment,
          pickupCourse: {
            bdoNumber,
            userNotes,
            anyContainer,
            addressId: anyContainer ? addressId : undefined,
            containerTypeId: pickupContainerTypeId
          },
          leaveCourse: {
            debrisTypeId,
            containerTypeId,
            userNotes: leaveNotes
          },
          course: {}
        }

        if (this.$route.query?.callEntryId) {
          params.course.callEntryId = Number(this.$route.query.callEntryId)
        }

        this.addNewSwapOrder({ endpoint: 'orders/createSwap', params })
          .then(() => {
            const { order, course, payment } = new Swap()
            this.order = order
            this.course = course
            this.payment = payment
            this.afterFormSubmitted(`Utworzono ${this.subjectInAccusative}`)
            if (this.$route.query.full) this.$router.go()
          })
      }
    }
  }
}
</script>
